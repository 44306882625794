@import url("https://fonts.googleapis.com/css2?family=Candal&family=Changa+One:ital@0;1&family=Exo:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Josefin+Slab:ital,wght@0,100..700;1,100..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Sarala:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  box-shadow: 0px 4px 45px 0px #00000040;
}

.navbar-brand img {
  width: 110px;
}
.nav-item a {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000 !important;
  margin: 0 10px;
}

.active {
  font-weight: 600 !important;
}

.nav-item a:hover {
  font-weight: 600;
}
.nav-item {
  position: relative !important;
}

.dropdown-menu {
  display: none;
  position: absolute;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-top: 2px solid #0e60a8 !important;
  border-radius: 0px !important;
  z-index: 100;
  opacity: 0; /* Hide initially */
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
  width: 250px;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.dropdown-menu ul li {
  padding: 10px;
  /* border-bottom: 1px solid #ccc; */
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

.dropdown-menu ul li a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: block;
}

.nav-item:hover .dropdown-menu {
  display: block;
  transition: all 0.3s ease-in-out;
  opacity: 1; /* Show with smooth transition */
  z-index: 100;
  transform: translateY(1px); /* Adjust dropdown position */
  border: none;
}

.nav-item:hover .dropdown-menu ul li:hover {
  background-color: #f0f0f0; /* Background color on hover */
}

@media (max-width: 500px) {
  .nav-fluid {
    padding: 0 20px !important;
  }
  .navbar-nav {
    margin-left: 0;
  }
  .nav-list li {
    margin-bottom: 3%;  
  }
}

.first-btn {
  border: 1px solid #0e60a8;
  background-color: #fff;
  color: #0e60a8;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
}

.first-btn:hover {
  background-color: #0e60a8;
  color: #fff;
  transition: all 0.3s ease;
}
.second-btn {
  border: 1px solid #0e60a8;
  background-color: #0e60a8;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 35px;
  border-radius: 25px;
}

.second-btn:hover {
  background-color: #fff;
  color: #0e60a8;
  transition: all 0.3s ease;
}

.footer {
  padding-top: 4%;
  background: #03347e;
}

.footer-content p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.footer-content h6,
.footer-list h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.footer-imgs img {
  padding-right: 5%;
}

.footer-list h3 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-top: 3%;
  padding-bottom: 5%;
}

.footer-list li {
  list-style: none;
  padding-bottom: 3%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footer-list li:hover {
  color: #e98137;
  cursor: pointer;
}

.footer-group {
  background: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 1%;
}

.footer-group input {
  background: transparent;
  border: 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footer-group input::placeholder {
  color: #b6b5b5;
}

.footer-group input:focus {
  box-shadow: none;
  outline: 0;
  color: #fff;
}

.footer-append span {
  background: #e98137;
  margin: 1%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #ff6363;
  cursor: pointer;
}

.footer-row2 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 19.69px;
  text-align: center;
  color: #fff;
  margin-top: 3% !important;
  padding-top: 2%;
  border-top: 1px solid #ffffff;
}

.footer-social {
  margin-top: 6%;
}

.footer-social i {
  padding: 5px 8px;
  background: #fff;
  border-radius: 50%;
  margin-right: 4%;
  cursor: pointer;
}

.bi-facebook:hover {
  color: #45c4e9;
}
.bi-twitter:hover {
  color: #03bff8;
}
.bi-instagram:hover {
  color: #ff6363;
}

.banner {
  background-image: url("../public/imgs-alu/Rectangle\ 8.png");
  background-size: cover;
  /* position: relative; */
}



.banner-icons img {
  margin-bottom: 10px;
}

.banner-left {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4% !important;
  padding-right: 4% !important;
}
.banner-icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 5%;
  background: #e98137a6;
  border-radius: 0px 25px 25px 0px;
  padding: 15px 10px 12px 0px;
}

.banner-left h1 {
  font-family: "Changa One", sans-serif;
  font-size: 58px;
  font-weight: 400;
  line-height: 75px;
  text-align: left;
  color: #fff;
}

.banner-button button {
  padding: 10px 45px;
  background: #e98137;
  border: 2px solid #e98137;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  margin-top: 5%;
}

.banner-button button:hover {
  background: transparent;
  color: #e98137;
  transition: all 0.5s ease-in-out;
}

.banner-img img {
  width: 100%;
  margin-left: 10px !important;
}

.about {
  background: linear-gradient(
      0deg,
      rgba(14, 96, 168, 0.09),
      rgba(14, 96, 168, 0.09)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09));
  padding-top: 4%;
  padding-bottom: 4%;
}

.main-heading h1 {
  font-family: "Jost", sans-serif;
  font-size: 44px;
  font-weight: 700;
  text-align: center;
  color: #252525;
  padding-bottom: 2%;
}

.main-heading span {
  border-bottom: 2px solid #0e60a8;
}

.about-img {
  padding-right: 3%;
}

.about-img img {
  width: 100%;
}

.about-content p {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 50px;
  text-align: justify;
  color: #555555;
  padding-left: 3%;
}

.about-button button {
  padding: 10px 40px;
  background: #e98137;
  border: 2px solid #e98137;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  margin-top: 7%;
  margin-left: 3%;
}

.about-button button:hover {
  background: transparent;
  color: #e98137;
  transition: all 0.5s ease-in-out;
}

.card-row {
  padding-top: 4%;
  padding-bottom: 4%;
}

.feature-card {
  padding: 5% !important;
  background: #ff990075;
  border: 2px solid #ff990075;
  border-radius: 20px;
  clip-path: polygon(15% 0%, 100% 0%, 100% 80%, 85% 100%, 0% 100%, 0% 20%);
  margin-bottom: 2%;
}

.feature-img {
  display: flex;
  justify-content: center;
}

.feature-img img {
  width: 110px;
}

.feature-card h2 {
  font-family: "Kanit", sans-serif;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: #03347e;
}

.feature-card p {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #555555;
}

.feature-card:hover {
  transition: all 0.3s ease-in-out;
  background: #ff9900c5;
}

.key {
  padding-top: 4%;
  padding-bottom: 4%;
}

.key-row {
  padding-top: 4%;
}

.key-card {
  padding: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 2%;
}

.key-card h3 {
  font-family: "Jost", sans-serif;
  font-size: 44px;
  font-weight: 700;
  color: #ECFF15;
}

.key-card-1 {
  background-image: url("../public/imgs-alu/coloring.png");
  background-size: cover;
}
.key-card-2 {
  background-image: url("../public/imgs-alu/design.png");
  background-size: cover;
}
.key-card-3 {
  background-image: url("../public/imgs-alu/machine.png");
  background-size: cover;
}

.man {
  padding-top: 4%;
  padding-bottom: 4%;
  background: #E4FFE3CC;
}

.man-row {
  padding-top: 4%;
}

.man-card {
  padding: 7%;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 25px 5px #00000040;
  margin-bottom: 6%;
}

.man-img {
  display: flex;
  justify-content: center;
}

.man-card h2 {
  font-family: "Candal", sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  color: #FF8C00;
  padding-top: 3%;
  padding-bottom: 3%;
}

.man-card p {
  font-family: "Sarala", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.09px;
  text-align: center;
  color: #555555;
}

.man-button {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.man-button button {
  padding: 6px 25px;
  background: transparent;
  border: 1px solid #FF8C00;
  border-radius: 25px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #FF8C00;
}

.main-banner {
  background-image: url("../public/imgs-alu/about-banner.png");
  background-size: cover;
  padding: 9%;
}


.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 70px;
  border-radius: 50px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.float2 {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  left: 70px;
  border-radius: 50px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  position: absolute;
  margin-top: 10px !important;
  font-size: 20px;
  margin-left: -10px;
  color: #fff;
}

.float2::after {
  content: attr(title);
  /* background-color: rgba(0, 0, 0, 0.8); */
  background-color: #ffb3007c;
  color: white;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  opacity: 1; /* Change opacity to 1 to make it always visible */
  transition: opacity 0.3s;
}

.float, .float2 {
  animation: zoomInOut 3s infinite alternate; 
}

@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Start with normal size */
  }
  50% {
    transform: scale(1.2); /* Zoom in halfway through the animation */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

.quality {
  background-image: url("../public/imgs-alu/quality-banner.png");
  background-size: cover;
  
}

.landing-form input {
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  color: #A1A1A1;
}

.faq-left {
  padding-top: 5%;
}

.faq-left h1 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  color: #2a2a2a;
}
.faq-left span {
  color: #ffb400;
}

.faq-left hr {
  width: 10%;
  color: #ffb400 !important;
  background-color: #ffb400 !important;
  height: 5px !important;
}

.faq-left p {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  color: #666666;
}

.faq-left button {
  border: 4px solid #ffb400;
  background: transparent;
  padding: 10px 35px;
  color: #ffb400;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-top: 4%;
}

.faq-left button:hover {
  background: #ffb400;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.faq-card {
  padding: 2%;
  border-bottom: 1px solid #d7d7d7;
}
.faq-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.faq-heading h3 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.faq-heading i {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffb400;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.faq-card p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
}

.catalogue {
  background-image: url("../public/imgs-alu/catalogue.png");
  background-size: cover;
}

.contact-bg {
  background-image: url("../public/imgs-alu/contact-bg.png");
  background-size: cover;
}

/*------------------- Admin Dashboard CSS ------------*/
.modal-title.h4 {
  font-size: 20px !important;
  font-family: "Poppins";
  font-weight: 600;
  color: #d80000;
}

.modal-content1 p{
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #410101;
}

.modal-footer-btn {
  padding: 5px 20px;
  background: #d80000;
  border: 1px solid #d80000;
  border-radius: 6px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}
/*----------------- SIDEBAR CSS------------------------ */
.sidebar {
  position: fixed;
  top: 0%;
  left: 0;
  height: 100%;
  width: 200px !important;
  background-color: #fff;
  color: #000;
  transition: all 0.5s ease;
  z-index: 100;
  border-radius: 0px 10px 10px 0px;
}

.sidebar.close {
  width: 78px !important;
}

/* --------- Logo ------------ */

.logo-box {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}

.logo-box img {
  margin-top: 5%;

  margin-left: 5%;
}
.sidebar.close .logo-box i {
  transform: rotate(180deg);
}

/* ---------- Sidebar List ---------- */
.sidebar-list {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto !important;
}

.sidebar-list::-webkit-scrollbar {
  display: none;
}

.sidebar-list li {
  transition: all 0.5s ease;
}

.sidebar-list li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  cursor: pointer;
}
.title i{
  color:#0d6efd !important;
}

.sidebar-list li.active .title {
  background-color: #0d6dfd4d;
}

.sidebar-list li.active .bi-chevron-down {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.sidebar-list li .title .link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar-list li .title i {
  /* height: 50px; */
  /* min-width: 78px; */
  text-align: center;
  line-height: 40px;
  color: #292d32;
  font-size: 20px;
  margin-left: 10px;
}
.sidebar-list li .title svg {
  margin-right: 25px;
  height: 50px;
  width: 23px;
  fill: #292d32;
  margin-left: 10px;
}

.sidebar-list li .title .name {
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  color: #0c0c0d;
}

.activeTab .name {
  font-size: 14px;
  font-weight: 600 !important;
  color: #4a92fe !important;
}

.css-1u0lry5-MuiChartsLegend-root {
  display: none;
}

/* ---------------- Submenu ------------- */
.sidebar-list li .submenu {
  display: none;

  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar-list li.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: #eeeeee;
}

.sidebar-list li.dropdown.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 0;
  background-color: #eeeeee;
}

.submenu .link {
  color: #000;
  font-size: 13px;
  padding: 5px 0;
  transition: all 0.5s ease;
  text-decoration: none;
}

.submenu-title {
  display: none;
}

/* ---------------- Submenu Close ------------- */
.sidebar.close .logo-name,
.sidebar.close .title .name,
.sidebar.close .title .bi-chevron-down,
.sidebar.close .side-profile .side-name {
  display: none;
}

.sidebar.close .sidebar-list {
  overflow: visible;
}

.sidebar.close .sidebar-list li {
  position: relative;
}

.sidebar.close .sidebar-list li .submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  height: max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  color: #000;
}

.sidebar.close .sidebar-list li:hover .submenu {
  opacity: 1;
  top: 0;
  pointer-events: initial;
  background-color: #1b59f8;
}

.sidebar.close .submenu-title {
  display: block;
  font-style: 18px;
}

.side-profile {
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  border-top: 0.5px solid #e5e5e5;
  padding-top: 5%;
  padding-bottom: 5%;
}

.side-name {
  margin-left: 7%;
}
.side-name h3 {
  font-family: "Inter", sans-serif;
  font-size: 12.93px;
  font-weight: 600;
  text-align: left;
  color: #000;
}
.side-name h5 {
  font-family: "Inter", sans-serif;
  font-size: 11.08px;
  font-weight: 400;
  text-align: left;
  color: #00000080;
}

.home {
  position: relative;
  padding: 2%;
  padding-top: 2%;
  left: 200px;
  width: calc(100% - 200px);
  /* height: 100vh; */
  transition: all 0.5s ease;
  background: #f5f5f5;
}

.sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
}

.home .toggle-sidebar {
  height: 60px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.home .toggle-sidebar i {
  font-size: 25px;
  margin-left: 15px;
}

.home .toggle-sidebar .text {
  font-size: 25px;
  font-weight: 600;
}

.dashboard {
  padding-top: 0%;
  padding-bottom: 5%;
}

.dashboard-header h1 {
  font-family: "Poppins", sans-serif;
  font-size: 22.17px;
  font-weight: 600;
  color: #000;
}
.dashboard-header {
  padding-bottom: 1%;
  border-bottom: 1px solid #0000001a;
}

.dash-row {
  padding-top: 3%;
  padding-bottom: 3%;
}

.dash-card {
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 16px;
  background: #fff;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
}
.dash-1 {
  display: flex;
  justify-content: space-between;
}

.dash-1 h3 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #0f172a;
}

.dash-1 span {
  color: #0d6efd;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 5px;
  background-color: #ddebff;
  border-radius: 4px;
}

.dash-2 {
  display: flex;
  justify-content: space-between;
  padding-top: 6%;
}

.dash-2 h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.dash-2 h5 {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.dash-box {
  box-shadow: 0px 1px 3px 0px #00000014;
  background: #fff;
  border-radius: 12px;
  padding: 5%;
}

.dash-box h2 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #202020;
}

.dash-box h5 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #898989;
}
.pie-label {
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
  margin-bottom: 2%;
}

.pie-label p {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #202020;
}
.pie-label span {
  background-color: #775da6;
  color: #775da6;
  border-radius: 10px;
  height: 2px;
  padding: 4px;
  margin-top: 6%;
  margin-right: 4px;
}
.span2 {
  background: #ffb1b7 !important;
}
.span3 {
  background: #70b6c1 !important;
}
.order1 {
  padding-bottom: 2%;
  border-bottom: 1px solid #eaeaea;
}

.order1 h3 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #0f172a;
}
.order1 h6 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #0f172a;
}
.order1 span {
  padding: 5px 8px;
  border: 1px solid #94a3b8;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.order2 {
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  border-bottom: 1px solid #eaeaea;
}

.order-img {
  margin-right: 3%;
}

.order-img img {
  padding: 5px;
  border: 1.81px solid #e2e8f0;
  border-radius: 8px;
}
.order2 h5 {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  text-align: left;
  color: #0f172a;
}

.order2 p {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #000;
}

.maintable-column {
  background-color: #fff;
  border: 1px solid #55565a1f;
  border-radius: 8px;
  padding: 20px;
  width: 100% !important;
}
.foot-tablerow h5 {
  font-size: 25px;
  margin: 15px 25px;
  color: #545454;
}
.table select {
  width: 100px;
  height: 30px;
}
.tr1 {
  background: #fcfcfc !important;
}
.dt-search {
  display: none;
}
th {
  background-color: #fcfcfc !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;

  color: #121212;
  padding: 12px !important;
  text-align: left !important;
}
td {
  background-color: #fff !important;
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #7d8398;
  padding: 10px 13px !important;
  text-align: left !important;
}
.table input[type="checkbox"] {
  border: 1px solid #7d83984d;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px #12121208;
}

td i {
  color: #0d6efd;
  padding: 5px;
  background: #d8e8ff;
  border-radius: 5px;
  cursor: pointer;
}

.table-banner {
  margin-left: 17% !important;
  /* padding: 5%; */
}
.dt-column-order {
  display: none !important;
}

.search-group {
  width: 30% !important;
  margin-bottom: 1%;
  margin-left: -1%;
  border: 1px solid #a8a8a870;
  background: transparent;
  border-radius: 10px;
  padding: 0.5%;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.search-prepend span {
  border: 0;
  background: transparent;
}
.search-group input {
  background: transparent;
}
.search-group input:focus {
  background-color: transparent;
}

.dash h3 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #0f172a;
}

.dash h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.pending span{
  display: flex;
  color: #d6a243 !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: #d6a2431f !important;
  padding: 4px 6px !important;
  border-radius: 40px;
  justify-content: center;
  border: 0;
}
.pending i {
  color: #d6a243;
  background: transparent;
}
.completed span{
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 6px !important;
  border-radius: 40px;
  justify-content: center;
  border: 0;
  color: #c3c561 !important; 
  background: #c5c3611f !important;
}
.success span{
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 6px !important;
  border-radius: 40px;
  justify-content: center;
  border: 0;
}

.success span.paid {
  color: #91c561 !important; 
  background: #91c5611f !important;
}
.success span.unpaid {
  color: #bb2e2e !important; 
  background: #c561611f !important;
}

.success i {
  color: #91c561;
  background: transparent;
}

.member-card {
  padding: 5%;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 2px 18px 0px #0000001a;
  background: #fff;
  margin-top: 5%;
}

.member-card h1 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.member-card p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #747474;
}

.member-card h4 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}
.member-card h5 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #0d6efd;
}
.member-row {
  padding: 0 !important;
  margin-top: 4% !important;
}

.settings {
  border: 1px solid #e3e3e3;
  box-shadow: 0px 2px 18px 0px #0000001a;
  background: #fff;
  padding: 2%;
  margin-right: 3%;
  margin-top: 3%;
}
.settings h1 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #24292f;
  margin-bottom: 2%;
}

.settings span {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #24292f;
  padding: 5px;
  border: 1px solid #cccccc;
  margin-left: 2%;
}

.settings-row {
  padding-top: 3%;
  padding-bottom: 2%;
  border-bottom: 1px solid #0000001a;
}

.settings-item label,
.pass-item label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #24292f;
}

.settings-item input {
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  background: #fafafa;
  padding: 2%;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
}

.settings-item input:focus {
  background: #d1d1d1;
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  outline: 0;
}

.pass-row {
  padding-top: 3%;
  padding-bottom: 3%;
}
.pass-row h1 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.pass-group {
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  background: #fafafa;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
}

.pass-prepend span {
  background: transparent;
  border: 0;
  margin: 7px;
  font-size: 15px;
  cursor: pointer;
}

.pass-group input {
  background: transparent;
  border: 0;
}
.pass-group input:focus {
  background: #d1d1d1;
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  outline: 0;
  transition: all 0.3s ease-in-out;
}

.app-input .form-control {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.app-input .form-control.is-invalid,
.is-invalid {
  border: 1px solid #fa011a !important;
  border-radius: 4px !important;
}
.is-invalid-input {
  border-right: 0 !important;
  border-radius: 4px 0px 0px 4px !important;
}
.is-invalid-span {
  border-left: 0 !important;
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid #fa011a !important;
}

.app-input .form-control {
  display: flex;
}
.app-input input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filter-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}


/*** Admin CSS ***/
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
  height: 200px;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  height: 200px !important;
}
.add-new-box-btn button{
  background-color: #0e60a8;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 8px;
}
.plan-column label{
color: #1d1b1b;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.submit-planbtn{
  display: flex;
  justify-content: center;
}
.submit-planbtn button{
  background: #e98137;
  padding: 10px 20px;
  width: 300px;
  color: #fff;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
}


.privacy {
  padding-top: 1%;
  padding-bottom: 4%;
}

.privacy h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 31px;
  padding-top: 3%;
  padding-bottom: 2%;
}

.privacy p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 31px;
  text-align: justify;
}

.privacy h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 31px;
}

.order-tracker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  margin-bottom: 0%;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
  text-align: center;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1;
}

.circle.complete {
  background-color: #38a3a5;
}

.label {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Poppins";
}

.label.complete {
  color: #38a3a5;
}

.date {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  color: gray;
}

.date.complete {
  color: #38a3a5;
}



.line-container {
  position: absolute;
  top: 15%;
  left: 55%;
  width: 100%;
  height: 3px;
  z-index: 0;
  background-color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line {
  flex-grow: 1;
  height: 100%;
  background-color: gray;
}

.line.complete {
  background-color: #38a3a5;
}

.table-style th {
  background: #E98137 !important;
  color: #fff;
}

.thanks {
  margin-top: 5%;
  margin-bottom: 5%;
  background: #ffffff;
  box-shadow: 0px 12px 45px 0px #00000040;
  border-radius: 16px;
  padding: 3%;
}

.thanks h1 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Montserrat";
  color: #0E60A8;
  text-align: center;
  margin: 3% 0;
}

.thanks p{
  font-family: "Euclid Circular A";
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.thanks-button {
  padding-top: 2%;
  display: flex;
  justify-content: center;
}

.thanks-button button {
  padding: 8px 25px;
  background: #E98137;
  border: 1px solid #E98137;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}
.thanks-button button:hover {
  background: #fff;
  color: #E98137;
  transition: all 0.3s ease-in-out;
}

.thanks-img {
  display: flex;
  justify-content: center;
}

.thanks-img img{
  width: 150px;
}


.ellipsis { 
  position: relative; 
  width: 15%;
} 
.ellipsis:before { 
  content: ' '; 
  visibility: hidden; 
} 
.ellipsis abbr { 
  position: absolute; 
  left: 0; 
  right: 0; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Container for the switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
}

/* Slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bb2e2e;
  transition: .4s;
  border-radius: 20px;
}

/* Slider before */
.slider:before {
  position: absolute;
  content: "";
  height: 12px; /* Adjust as needed */
  width: 12px; /* Adjust as needed */
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #91c561; 
}

/* Slider when the checkbox is checked */
input:checked + .slider:before {
  transform: translateX(14px); /* Move the slider to the right */
}
